.gallery{
    -webkit-column-count:3;
    -moz-column-count:3;
    column-count:3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 0 12px;
}

.gallery .pics
{
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
}

@media(max-width:991px){
    .gallery{
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
}

.gallery .pics:hover{
    filter: opacity(0.2);
}

@media(max-width:480px){
    .gallery{
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
    }
}

.trailer-gallery
{
    position:fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(20px);
    visibility: hidden;
    opacity: 0;

}

.trailer-gallery.video-active
{
    visibility: visible;
    opacity: 1;
}

@media screen and (max-width: 480px){
    .video-gallery
    {
        max-width: 100%;
        outline: none;
        cursor: pointer;
    }
}



@media screen and (min-width: 991px){
.video-gallery
{
    max-width: 900px;
    outline: none;
    cursor: pointer;
}
}

@media screen and (max-width: 991px){
    .video-gallery
    {
        max-width: 70%;
        outline: none;
        cursor: pointer;
    }
}

.close
{
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    max-width: 32px;
}

