@import '../App.scss ';
@keyframes blink {
    from { border-color: transparent }
    to { border-color: white; }
}

@media screen and (max-width: 990px){

    .navbar{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: transparent;
        height: 90px;
        font-size: 20px;
        transition: background-color 1s linear;
    }
}


@media screen and (min-width: 990px){
.navbar{
    padding: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    height: 90px;
    font-size: 20px;
    transition: background-color 1s linear;
}
}

@media screen and (min-width: 990px){
.navbar-brand{
 font-size: 3em;
 color: white !important;
 padding: 0px 60px;
 text-decoration: none;
}
}

@media screen and (max-width: 990px){
    .navbar-brand{
     font-size: 1em;
     color: white !important;
     padding: 0px 30px;
     text-decoration: none;
}
}

.navbar-brand span{
    font-size: 4em;
    color: white !important;
    padding: 0px 0px;
}


@media screen and (max-width: 990px){
    .navbar-nav{
        padding: 0px 60px;
        margin-top: 40px;
        list-style: none;
        display:inline-block;
        font-size: 1rem;        
    }

}


.navbar-nav{
    padding: 0px 60px;
    margin-top: 40px;
    list-style: none;
    display:inline-block;
    font-size: 2.5rem;        
}

@media screen and (max-width: 990px){
.navbar-nav-menu{    
        display: none;
        
    }
}

.nav-link{
    color: white !important;
    margin-left: 8px;
    text-decoration: none;
}

.nav-link:hover{
        overflow: hidden;
        border-right: .15em solid white;
        white-space: nowrap;
        font-size: 3.5rem;
        width: 0;
        animation: 
        typing 1s steps(20, end) forwards,
        blink .8s infinite;
}

.header{
    position: relative;
}

@media screen and (max-width: 990px){

    .navbar-scroll{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: black;
        height: 90px;
        font-size: 20px;
        transition: background-color 1s linear;
    }
}


@media screen and (min-width: 990px){
.navbar-scroll{
    padding: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: black;
    height: 90px;
    font-size: 20px;
    transition: background-color 1s linear;
}
}

@media screen and (min-width: 990px){
    .burger{
        position: fixed;
        display: none;
    }
    }
    
    @media screen and (max-width: 990px){
        .burger{
            cursor: pointer;
            padding-right: 50px;
        }
        }

@media screen and (max-width: 990px){
.navbar-scroll .active
{
    position: absolute;
    width: 100%;
    height: 270px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    top: 90px;
    left:0;
    right:0;
    background-color: black;
    transition: background-color 1s linear;

}
}

@media screen and (max-width: 990px){
.navbar .active
    {
        position: absolute;
        width: 100%;
        height: 270px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        top: 90px;
        left:0;
        right:0;
        background-color: transparent;
        transition: background-color 1s linear;
    
    }
    }

@media screen and (max-width:990px){
    .navbar-brand img{ 
        width: 60px;
    }
}

@media screen and (min-width:990px){
    .navbar-brand img{ 
        width: 150px;
    }
}


