section
{
    position: relative;
    width: 100%;
    height: auto;
    background: black;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}


.row
{
    font-size: 80px;
    position: relative;
    width: 100%;
    top: 10px; 
    height: 150px;
    display: flex;
    padding: 10px 0;
    white-space: nowrap;
    background-color: black;
    justify-content: center;    
}

.iconTool
{
    color: #262626;;
    transition: 1s;
    padding: 0 5px;
    user-select: none;
}

.iconTool:hover
{
    color:#0f0;
    text-shadow: 0 0 90px #0f0;
}


.iconToolFlag
{
    opacity: 50%;
    color: #262626;;
    transition: 1s;
    padding: 0 5px;
    user-select: none;
    width: 1em;
    height: 1em;
}

.iconToolFlag:hover
{
    opacity: 100%;
}


.row-plan
{
    font-size: 80px;
    position: relative;
    width: 100%;
    top: 10px; 
    display: flex;
    padding: 10px 0;
    background-color: black;
    justify-content: center;
    padding-bottom: 50px;   
}

.box{
    margin: 5px;
    width: 30%;
    background-color:rgb(33 33 33 / 66%);
    color: #262626;
    border: 1px rgb(33 33 33 / 66%);
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    word-break: break-word;
    color: white;
}


.blur{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 0px 0px 5px 5px;
}

.blur img{
    transition: 2s ease;
    width: 100%;
    filter: blur(5px);
    border-radius: 0px 0px 5px 5px;
    }
    
    .blur img:hover {
    transition: 2s ease;
    filter: blur(0px);
    }
    
@media screen and (min-width: 700px){
    
    .boxText
    {
        min-height: 400px;
        height: 400px;    
    }

}

@media screen and (max-width: 699px){
    
    .row-plan {
        font-size: 80px;
        position: flex;
        /* width: 90%; */
        top: 10px;
        /* display: flex; */
        padding: 10px 0;
        background-color: black;
        padding-bottom: 50px;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: center;
    }

    .box{
        width:90%;
    }

    .boxText
    {
        min-height: 400px;
        height: 400px;    
    }

}

@media screen and (max-width: 220px){


    .boxText
    {
        min-height: auto;
        height: auto;    
    }
}
