.footer-row
{
    font-size: 80px;
    position: relative;
    width: 100%;
    height: 200px;
    display: flex;
    padding: 10px 0;
    white-space: nowrap;
    background-color: black;
    justify-content: center;    
}

@media(max-width: 480px)
{
    .footer-row {
        font-size: 40px;
        height: 120px;
    }
}

@media(max-width: 991px)
{
    .footer-row {
        height: 120px;
        font-size: 50px;
    }
}

.iconTool
{
    color: #262626;;
    transition: 1s;
    padding: 0 5px;
    user-select: none;
    size: 64px;
}

.iconTool:hover
{
    color:#0f0;
    text-shadow: 0 0 90px #0f0;
}

