
.main{
    .code{
        width: 1080px;
        height: auto;
        margin: 0 auto;
        display: flex;
        align-items: center;
        visibility: hidden;
    }
}

.img-container{
    width: 1080px;
    height: 891px;
    position: relative;
    overflow: hidden;
    &:after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        background: black;
    }
    img{
        width: 1080px;
        position: absolute;
    }

}

.trailer, .trailerTwo
{
    position:fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(20px);
    visibility: hidden;
    opacity: 0;

}

.trailer.active, .trailerTwo.active
{
    visibility: visible;
    opacity: 1;
}

.trailer video, .trailerTwo video
{
    max-width: 900px;
    outline: none;
    cursor: pointer;
}

@media screen and (max-width: 480px){
   .trailer video, .trailerTwo video
   {
       max-width: 100%;
       outline: none;
       cursor: pointer;
   }
}

@media screen and (min-width: 991px){
   .trailer video, .trailerTwo video
   {
       max-width: 900px;
       outline: none;
       cursor: pointer;
   }
   }
   
   @media screen and (max-width: 991px){
      .trailer video, .trailerTwo video
       {
            max-width: 70%;
           outline: none;
           cursor: pointer;
       }
   }

.close
{
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    max-width: 32px;
}

.titleAndButton{

    text-align: center;
    margin-bottom: 20px;
    color:white;
    font-size: 3em;
}

@media(max-width:480px){
   .titleAndButton{

      text-align: center;
      margin-bottom: 20px;
  }
}

p
{
   color: white;
   text-align: center;
   padding: 10px 50px;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
   sans-serif;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
}


#cover-img{
   
   width: 1300px;
   height: 900px;
   background: url('../../images/slg-portada.png') no-repeat;
   background-size: 1300px 900px;
   -moz-box-shadow: inset 0px 0px 50px 20px #000000;
   -webkit-box-shadow: inset 0px 0px 50px 20px #050505;
   box-shadow: inset 0px 0px 50px 20px #000000;
   align-self: center;
 
}


.button-layout{
   width: 100%;
   height: 100px;
   position: relative;
}

// setup button:

$black: #191919;
$white: #FFF;
$pear: #FFF;
$buttonWidth: 185px;
$buttonHeight: 60px;



// helper cleasses:

.centered {
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   margin: auto;
}

.full {
   width: 100%;
   height: 100%;
}

// button style:

.button {
    @extend .centered;
    width: $buttonWidth + 20px;
    height: $buttonHeight + 20px;
    perspective: 1800px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: black;
 
    .qube {
       position: relative;
       height: $buttonHeight;
       width: $buttonWidth;
       perspective: 1800px;
 
       .front, .back {
          @extend .full;
          backface-visibility: hidden;
          transform-style: preserve-3d;
          transform: rotateX(-90deg);
          transition: transform 0.3s ease;
          position: absolute;
          color: $white;
          line-height: $buttonHeight;
          text-align: center;
       }
 
       .front {
          background-color: $black;
 
          &::before {
             @extend .centered;
             @extend .full;
             content: '';
             display: block;
             z-index: -1;
          }
 
          &::after {
             background: $white;
             content: '';
             height: 155px;
             left: -100px;
             opacity: .4;
             position: absolute;
             top: -50px;
             transform: rotate(35deg);
             transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
             width: $buttonHeight - 10px;
             z-index: 1;
          }
       }
 
       .back {
          background-color: $pear;
          color: $black;
          transform: rotateX(0deg) translateZ($buttonHeight);
       }
 
       &:hover {
 
          .front {
             transform: rotateX(0deg) translateZ( $buttonHeight / 2 );
          }
 
          .back {
             transform: rotateX(90deg) translateZ( $buttonHeight / 2 );
          }
       }
    }
 
    &:active {
 
       .front {
          border-radius: 0;
          background-color: transparent;
          color: white;
 
          &::before {
             background-color: transparent;
             transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
          }
 
          &::after {
             left: 120%;
             transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
          }
       }
    }
 }

@media screen and (max-width: 250px){
.button {
   width: 80%;
   padding: 20px;
}
}