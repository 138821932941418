.header{
    position: relative;

    
}


.header img{
    width: 100%;
    height: 100vh;
    object-fit: cover;

}

.ImgOver{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: black;
    background: linear-gradient(to top,#000002, #000000, #00879399, #00bf7299, #a8eb1200);
}

.content{
    position: absolute;
    bottom: 10px;
    color: white;
    font-size: 3em;
    width: 100%;
    text-align: center;
}

.headerText{
    position: relative;
    width: 100%;
    height: 150vh;
}

.contentText{
    position: static;
    width: 95%;
    height: 500px;
    top: 20px;
    color: white;
    font-size: 30px;
    text-align: center;
}



@media(max-width:991px){
    .content{
        position: absolute;
        bottom: 10px;
        color: white;
        font-size: 3em;
        
    }
}

@media(min-width:990px){
    .contentText{
        height: 900px;
    font-size: 30px;
    }
}

@media(max-width:990px){
    .contentText{
        height: 900px;
    font-size: 17px;
    }
}

@media(max-width:250px){
    .contentText{
        height: auto;
    font-size: 15px;
    }
}
